.bioContainer { 
    display: inline-block;
    color: #fff;
    font-size: 17px;
    padding: 20px;
    line-height: 6vw;
    text-align: center;
    background-color: #18191a;
}

@media only screen and (min-width:1024px) {  
    .bioContainer {
        line-height: 50px;
    }
}