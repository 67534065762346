.RoadMapContainer {
    width: 100%;
    display: inline-block; 
    margin-bottom: 20px;
    margin: auto;
    padding: 20px;
}

.roadMapItemContainer {
    display: flex; 
    justify-content: center;
}

.roadMapItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 560px;
    padding: 20px;
    background: #18191a;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    box-shadow: 0px 0px 5px -2px white;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: 25px;
    &.p1 {
        background-image: url("../../files/images/planets/p1.png");
        background-size: 50px;
    }
    &.p2 {
        background-image: url("../../files/images/planets/p2.png");
    }
    &.p3 {
        background-image: url("../../files/images/planets/p3.png");
    }
    &.p4 {
        background-image: url("../../files/images/planets/p4.png");
    }
    &.p5 {
        background-image: url("../../files/images/planets/p5.png");
    }
    &.p6 {
        background-image: url("../../files/images/planets/p6.png");
    }
    &.p7 {
        background-image: url("../../files/images/planets/p7.png");
    }
    &.p8 {
        background-image: url("../../files/images/planets/p8.png");
    }
    &.sc {
        background-image: url("../../files/images/planets/sc.gif"); 
        background-size: 140px;
        background-position: 10px;
    }
}

.roadMapItemLeft {
    padding-left: 90px;
    width: 300px;
} 
.roadMapItemCheck {
    width: 160px;     
}


@media only screen and (max-width:1024px) {
    .roadMapItemContainer {
        flex-direction: column;
        margin-bottom: 50px;
    }
    .roadMapItemLeft {
        width: 100%;
        height: 80px;
    }
    .roadMapItem {
        width: 100%;
        box-shadow: 0px 0px 8px -2px white;
    }
    .roadMapItemCheck{
        height: 10px;
    }
    .roadMapItem.sc {
        background-position: center;
    }
} 