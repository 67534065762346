.VideoSequenceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin: auto; 

    .videoSection {
        display: flex;
        flex-wrap: wrap;    
        justify-content: center;
        .videoContain {
            display: flex;
            padding: 10px;  
            position: relative;
            video {  
                width: 100%;
                max-height: 340px;
                max-width: 340px;
                margin: auto;
                cursor: pointer;
            } 
        }
    }
    
    .arrowContain {
        width: 100%;
        text-align:center;
        padding-bottom: 40px;
        font-size: 20px;
        img {
            width: 60px;
            margin: 10px 10px -16px 10px;
        }
        span {
            color: #fff;
        }
    }
    
}


@media only screen and (max-width:1024px) {
    .VideoSequenceContainer {
        .videoSection {
            .videoContain{
                width: 50% !important;
            }
        }
        .arrowContain {
            padding-bottom: 20px;
            font-size: 13px;
            img {
                width: 50px;
            }
            span {
                color: #fff;
            }
        }
    }
} 