.socialsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 9;
    right: 20px;
    top: 20px;
    img {
        width: 60px;
        height: 60px;
        padding: 10px; 
    }
    a {
        width: 60px;
        height: 60px;
        margin: 8px;
        cursor: pointer;
        color: #fff;
        background: #a656af94;
        border: 0 solid;
        box-shadow: inset 0 0 20px #ffffff00;
        outline: 2px solid; 
        -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
                transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        outline-color: #ffffff80;
        outline-offset: 0px;
    }
    a:hover { 
        box-shadow: inset 0 0 25px #ffffff80, 0 0 20px #ffffff33;
        outline-offset: 10px;   
        outline-color: #ffffff00;  
    }
}


@media only screen and (max-width:1024px) {
    .socialsContainer { 
        right: 0px;
        top: 10px;
        justify-content: center;
    }
}