.topButtonsContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    margin-top: -75px;
}
.seeCollectionBtn {
    cursor: pointer;
    margin: 0 auto 2em;
    text-transform: uppercase;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background: #a656af94;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid; 
    -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    outline-color: #ffffff80; 
}
.seeCollectionBtn:hover {
    border: 1px solid;
    box-shadow: inset 0 0 30px #ffffff80, 0 0 60px #ffffff33;
    outline-offset: 25px;
    outline-color: rgba(255, 255, 255, 0);  
}


@media only screen and (max-width:1024px) {
    .topButtonsContainer { 
        margin-top: -50px;
    }
}